@import 'variables';
@import 'node_modules/react-day-picker/dist/style.css';

@media (min-width: 768px) {
	.rtl {
		.date-picker-range,
		.airport-select .items-list {
			right: 0;
			left: unset;
		}
	}
}
@media screen and (min-width: 320px) and (max-width: 575px) {
	.rtl {
		#date-from,
		#check-in-date {
			.date-picker-range {
				left: var(--picker-right-offset);
			}
		}
		.cars-filter-form #date-to {
			.date-picker-range {
				right: 0;
				left: unset !important;
			}
		}
		#flights-filter-form #date-to,
		#check-out-date {
			.date-picker-range {
				right: var(--picker-right-offset);
				left: unset !important;
			}
		}
	}

	#flights-filter-form #date-to,
	#check-out-date {
		.date-picker-range {
			left: var(--picker-right-offset);
		}
	}
}

@media screen and (max-width: 320px) {
	.rtl {
		.cars-filter-form,
		#flights-filter-form,
		.date-picker-range {
			right: unset !important;
			left: calc(var(--picker-right-offset) / 2) !important;
		}
	}
}

.date-picker .selected {
	text-transform: capitalize;
}

.rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
	background-color: #049dd9 !important;
	color: #f0f8ff !important;
}

.rdp-day {
	font-size: 16px;
	font-family: $fontFamily, sans-serif;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: -0.24px;
	text-align: center;
	color: $black;
	margin: 2px 0px;
	display: table-cell;
	padding: 5px 0 !important;
	border-radius: 50%;
	vertical-align: middle;
	cursor: pointer;
	flex: 1;
	transition: all 0.2s ease-in-out;
}

/* DayPicker styles */

.rdp {
	--rdp-accent-color: #049dd9;
	--rdp-cell-size: 37px !important;
	margin: 0px 4px 4px !important;
	font-family: 'Assistant', sans-serif;
}

.rdp-months {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.rdp-month {
	display: table;
	margin: 0 0;
	border-spacing: 0;
	border-collapse: collapse;

	-webkit-user-select: none;

	-moz-user-select: none;

	-ms-user-select: none;

	user-select: none;
}

.rdp-nav_button {
	position: absolute !important;
	left: auto;
	display: inline-block;
	margin-top: 2px;
	width: 1.75em !important;
	height: 1.75em !important;
	background-position: center;
	color: #8b9898 !important;
	cursor: pointer !important;
	transition: all 0.3s;
	top: 50%;
	transform: translateY(-50%);

	&:hover {
		opacity: 0.8;
		background-color: transparent !important;
	}
}

.rdp-nav_button_previous {
	margin-right: 1.5em;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAAAXNSR0IArs4c6QAAAghJREFUeAHt2k9OwkAUBnCqW04Aid7AYMLeXoC17k3gGJSTeAM3XqCugQvAjgUHcKumvpfYpKkKJPjefK/53qLTP2ln5tdO0ryZXo9BAQpQgAIUoAAFKEABClCAAt4Cl94VnlvfeDwuBoPB3X6/fz33WafcHwpIcaqqmkvH8uFw2PNACgPUwKlfvAvSRV0bcvkLTt3cm6IoTPuQ1TWhln/hZFn23O/378uy/LBsOzRQahyFhwVCwIEFQsGBBELCgQNCw4ECQsSBAULFgQBCxkkOhI6TFCgCTjKgKDhJgCLhuANFw3EFiojjBhQVxwUoMo450CEcyS0/rNfrd20Ecpjlg7qAoy/OBKgrOApkmvDWCpohw+ptMpl8Ns+h75tM+8h8VSnzVvp15i2A0Xa7vZpOpy+SbK9a1yAPTYC0pweQbiMhmQF1BckUqAtI5kDRkVyAIiO5AUVFcgWKiOQOFA0pCdAxpM1mcz2bzSB+JpMBNZB0N9dNI0YoSEmBFESX0elyOolcN42AQEoOpCDISBBAyEgwQKhIUECISHBAaEiQQMeQPPNJsEBHkNySbiZJe+3cf4ZMAswln120nylrpZ+Wy+WjlGbpW9ekfbuDpx6vVquFIPwAkvt3ljjaPughpg2so/0zKTALgSvq6yy/BXS46bwbQShAAQpQgAIUoAAFKEABClCguwJfnlHcZJEBT7IAAAAASUVORK5CYII=');
	left: 5px;
	background-size: cover;
	svg {
		display: none;
	}
}

.rdp-nav_button_next {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAAAXNSR0IArs4c6QAAAehJREFUeAHt291tgzAUBeCQdgqW6AMwRQchbRboBO0GTToTTNEX1LduQM+VggQ8VJGCfc+NjiXLBCH/fLaRMWG3U5CABCQgAQlIQAISkIAEJCCBuxWo67qtqupzHMciUiMfc1TWcABzQllF0zR7HL8URTHmKPvWMpL35hxnqixwvrquC4G0nyqdKsVoeULei47AuRYj6YR0cT5VHW7JNzlQ3/dHjJjzupJRkLL0oI0UjBi7QR/WUOzTLQuQoURFygYUFSkr0AzJbtCt/Z4HxumWHchALtMtBJILUCQkN6AoSK5AEZCSLxQN4b+AG/N4eeygXEy6j6AJj3WdRANkUIxIVECMSHRAMySKdRIlEBMSLRALEjUQAxI9kDdSCCBPpDBAXkjujxrW8GuDPZYg/l57/RbXhRpBePH4gUa/rRuecqMtDJAHjnVECCAvnBBAnjj0QN441EAMOLRALDiUQEw4dEBsOFRAjDg0QKw4FEDMOO5A7DiuQBFw3ICi4LgARcLJDhQNJytQRJxsQFFxDCj5njRw3lFO1m1Sa9hWITkQKvqNuPguI+Ue8lYwUz4P00GqdBiGvizLH+T/jAib4ow/TL0iXaClKj9MvphqB8Rwn0OFAVZFJSABCUhAAhKQgAQkIAEJ3JHAH1nNoLdy+NWaAAAAAElFTkSuQmCC) !important;
	top: 50%;
	right: 5px !important;
	transform: translateY(-50%);
	background-size: cover !important;

	svg {
		display: none;
	}
}

.rdp-nav_button--interactionDisabled {
	display: none;
}

.rdp-caption {
	display: flex;
	text-align: left;
	border-bottom: solid 1px #cccccc;
	padding: 10px 20px !important;
	margin-bottom: 8px;
	position: relative;
	justify-content: center !important;
}

.rdp-caption_label {
	text-align: center;
	font-family: HelveticaNeue, sans-serif !important;
	text-transform: capitalize;
	font-size: 18px !important;
	font-weight: 700 !important;
	font-style: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: -0.3px;
	color: $black;
}

.rdp-body {
	display: table-row-group;
}

.rdp-weeknumber {
	display: table-cell;
	padding: 0.5em;
	min-width: 1em;
	border-right: 1px solid #eaecec;
	color: #8b9898;
	vertical-align: middle;
	text-align: right;
	font-size: 0.75em;
	cursor: pointer;
}

.rdp-head_cell {
	text-transform: capitalize !important;
}

/* Default modifiers */

.rdp-day--today {
	font-weight: 700;
}

.rdp-day_outside {
	color: #8b9898;
	cursor: pointer;

	&:hover {
		color: #454545;
	}
}

.rdp-day_outside.rdp-day_selected {
	color: #fff !important;
}

.rdp-day--disabled {
	color: #dce0e0;
	cursor: default !important;
	pointer-events: none;
}

/* Example modifiers */

.rdp-day--sunday {
	background-color: #f7f8f8;
}

.rdp-day--sunday:not(.rdp-day--today) {
	color: #dce0e0;
}

.rdp-day_selected:not(.rdp-day--disabled) {
	position: relative;
	color: #f0f8ff;
}

.rdp-day_selected:not(.rdp-day--disabled):hover {
	background-color: $blue;
}

.rdp-day:not(.rdp-day--disabled):not(.rdp-day_selected):hover {
	background-color: #f1f0ff;
}

.rdp-nav_button[disabled] {
	display: none;
}
